@import url("https://fonts.googleapis.com/css?family=Droid+Sans+Mono");

//$easeIn: cubic-bezier(0.25, 0.46, 0.45, 0.94)
//$easeOut: cubic-bezier(0.55, 0.085, 0.68, 0.53)
$easeInOut: cubic-bezier(0.15, 0.45, 0.28, 1);
$easeOut: cubic-bezier(0.37, 0.01, 0.94, 0.35);

$turnDuration: 0.6s;

// Animation mixin
@mixin animate(
  $name,
  $easing,
  $duration: 300ms,
  $delay: 0s,
  $iteration: 1,
  $direction: normal,
  $fill-mode: forwards
) {
  animation: $name $duration $easing $delay $iteration $direction $fill-mode;
}


$border: rgba(61, 179, 169, 0.5);
$card: white;
$font-family: "Montserrat", sans-serif;
$font-size: 3em;
$font-width: bold;
$background-color: #3db3a9;
$font-color: white;

.countdown-header {
  margin-bottom: 10px;
  color: #283b5c;
  font-weight: bold;
}

.countdown-timer-units {
  margin-top: 10px;
  font-size: 18px;
  color: #283b5c;
}

.flipClock {
  display: flex;
  justify-content: space-between;
  width: 3 * 80px + 80px;
}

.flipUnitContainer {
  display: block;
  position: relative;
  width: 67px;
  height: 70px;
  perspective-origin: 50% 50%;
  perspective: 300px;
  background-color: $card;
  border-radius: 8px;
  margin-right: 10px;
  box-shadow: 0px 10px 10px -10px grey;
}

.upperCard,
.lowerCard {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 50%;
  overflow: hidden;
  background: $background-color;
  border: 1px solid $border;
  span {
    font-size: $font-size;
    font-family: $font-family;
    font-weight: $font-width;
    color: $font-color;
  }
}

.upperCard {
  align-items: flex-end;
  border-bottom: 0.5px solid lighten($color: #3db3a9, $amount: 40%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  span {
    transform: translateY(50%);
  }
}

.lowerCard {
  align-items: flex-start;
  border-top: 0.5px solid lighten($color: #3db3a9, $amount: 40%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 8px;
  span {
    transform: translateY(-50%);
  }
}

.flipCard {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  background: $background-color;
  backface-visibility: hidden;
  span {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-width;
    color: $font-color;
  }
  &.unfold {
    top: 50%;
    align-items: flex-start;
    transform-origin: 50% 0%;
    transform: rotateX(180deg); // from 180 to 0
    background-color: $background-color;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 0.5px solid $border;
    border-top: 0.5px solid lighten($color: #3db3a9, $amount: 40%);
    span {
      transform: translateY(-50%);
    }
  }
  &.fold {
    top: 0%;
    align-items: flex-end;
    transform-origin: 50% 100%;
    transform: rotateX(0deg); // from 0 to -180
    background-color: $background-color;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 0.5px solid $border;
    border-bottom: 0.5px solid lighten($color: #3db3a9, $amount: 40%);
    span {
      transform: translateY(50%);
    }
  }
}

.fold {
  @include animate(fold, $easeInOut, 0.6s);
  transform-style: preserve-3d;
}

.unfold {
  @include animate(unfold, $easeOut, 0.6s);
  transform-style: preserve-3d;
}

@keyframes fold {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%,
  99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes unfold {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}
