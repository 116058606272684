.ReactCarouselWrapper {
  overflow: hidden;
  position: relative;
}

.CarouselController {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 15px 0;
  z-index: 100;
}
.CarouselController button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #c2cce1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  outline: none;
}
.CarouselController button.next {
  margin-left: auto;
}
.CarouselController button img {
  width: 16px;
}

.carouselItemContainer {
  /* width: calc((var(--num) * 470px)); */
  display: inline-grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  /* flex-wrap: nowrap; */
  list-style: none;
  gap: 150px 20px;
  padding: 0;
  margin-bottom: 0;
  height: 250px;
  width: 100%;
}

.carouselItemContainer.prev {
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-name: prev;
}

.carouselItemContainer.next {
  animation-duration: 0.2s;
  animation-timing-function: ease;
  animation-name: next;
}

.carouselItemContainer .carouselItem {
  width: 100%;
  box-sizing: border-box;
  margin-right: 20px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.carouselItemContainer .carouselItem > * {
  width: 100%;
}

@keyframes next {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px + 470px));
  }
}

@keyframes prev {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(calc(0px - 470px));
  }
}
