img{
    max-width: 100%;
    border-radius: $border-radius-small;
}





.login-lefts{
    width: 900px;
    height: 100%;
    border: none
}
.login-right{
    margin-top: 188px;

}
.login-img{
    margin:auto;
    display:block;
    vertical-align: middle;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
