@media screen and (max-width: 1500px) and (min-width: 768px) {
  .dashboard-hours-img {
    padding: 3px 0px 0px 0px !important;
  }
  .card-stats {
    .card-body {
      .numbers {
        font-size: 22px;
      }
    }
  }
  .stats {
    font-size: 13px;
  }
}
@media screen and (max-width: 991px) {
  .navbar {
    padding: 0;

    &.navbar-absolute {
      padding-top: 0;
    }

    .navbar-brand {
      font-size: 16px;
      margin-right: 0;
    }
    .container-fluid {
      padding: 10px 15px !important;
    }

    .navbar-nav {
      .nav-item {
        border: 0 !important;
      }
    }
  }

  .profile-photo .profile-photo-small {
    margin-left: -2px;
  }

  .button-dropdown {
    display: none;
  }

  #minimizeSidebar {
    display: none;
  }

  .navbar {
    .container-fluid {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-collapse {
      .input-group {
        margin: 0;
        margin-top: 5px;
      }
    }

    .navbar-nav {
      .nav-item:first-child {
        margin-top: 10px;
      }
      .nav-item:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .dropdown.show .dropdown-menu {
      display: block !important;
      transform: translate3d(0px, 0px, 0px) !important;
    }

    .dropdown .dropdown-menu {
      display: none;
    }

    .dropdown.show .dropdown-menu,
    .dropdown .dropdown-menu {
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      width: 100%;
      margin: 0px 1rem;
      margin-top: 0px;
      box-shadow: none;
      top: 130% !important;
      left: -15px !important;
      &:before {
        display: none;
      }
    }

    .dropdown-menu .dropdown-item:focus,
    .dropdown-menu .dropdown-item:hover {
      color: $white-color;
    }

    &.bg-white .dropdown-menu .dropdown-item:focus,
    &.bg-white .dropdown-menu .dropdown-item:hover {
      color: $default-color;
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $default-color;

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      & + .navbar-toggler-bar.navbar-kebab {
        margin-top: 3px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background-color: $default-color;
    }

    & .toggled .navbar-toggler-bar {
      width: 24px;

      & + .navbar-toggler-bar {
        margin-top: 5px;
      }
    }
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .nav-open {
    .main-panel {
      right: 0;
      @include transform-translate-x($sidebar-width);
    }
    .sidebar {
      @include transform-translate-x(0px);
    }

    body {
      position: relative;
      overflow-x: hidden;
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    outline: 1px solid transparent;
  }
  .bar1 {
    top: 0px;
    @include bar-animation($topbar-back);
  }
  .bar2 {
    opacity: 1;
  }
  .bar3 {
    bottom: 0px;
    @include bar-animation($bottombar-back);
  }
  .toggled .bar1 {
    top: 6px;
    @include bar-animation($topbar-x);
  }
  .toggled .bar2 {
    opacity: 0;
  }
  .toggled .bar3 {
    bottom: 6px;
    @include bar-animation($bottombar-x);
  }

  @include topbar-x-rotation();
  @include topbar-back-rotation();
  @include bottombar-x-rotation();
  @include bottombar-back-rotation();

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  .footer {
    .copyright {
      text-align: right;
    }
  }

  .section-nucleo-icons .icons-container {
    margin-top: 65px;
  }

  .navbar-nav {
    .nav-link {
      i.fa,
      i.nc-icon {
        opacity: 0.5;
      }
    }
  }

  .sidebar,
  .bootstrap-navbar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 250px;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    @include transform-translate-x(-250px);
  }

  .main-panel {
    width: 100%;
  }

  .timeline {
    &:before {
      left: 5% !important;
    }

    > li > .timeline-badge {
      left: 5% !important;
    }

    > li > .timeline-panel {
      float: right !important;
      width: 82% !important;

      &:before {
        border-left-width: 0 !important;
        border-right-width: 15px !important;
        left: -15px !important;
        right: auto !important;
      }

      &:after {
        border-left-width: 0 !important;
        border-right-width: 14px !important;
        left: -14px !important;
        right: auto !important;
      }
    }
  }
  .offer-navbar-container {
    width: 95% !important;
  }
  .black-friday-wrapper {
    padding-top: 100px !important;
  }
  .black-friday-image {
    width: 85% !important;
    margin-top: -40px !important;
  }
}

@media (max-width: 1366px) {
  .payment-rate-th {
    width: 150px !important;
  }
}
@media (min-width: 1400px) {
  .register-form {
    .form-group {
      margin-bottom: 25px !important;
    }
  }
}
@media (max-width: 1100px) and (min-width: 992px) {
  .activity-filter-button {
    width: 80px !important;
  }
  .seven-cols {
    .col-md-1,
    .col-sm-1,
    .col-lg-1 {
      flex: 0 0 25% !important;
      max-width: 25% !important;
    }
  }
}
@media screen and (max-width: 1400px) {
  .monthly-timesheet-calendar {
    height: 600px !important;
    padding-bottom: 100px !important;
  }
  .monthly-timesheet-time {
    font-size: 14px !important;
    margin-top: 14px !important;
  }
  .monthly-timesheet-timespan {
    margin-right: 4px !important;
  }
  .monthly-timesheet-header {
    font-size: 17px !important;
  }
  .monthly-timesheet-total {
    width: 150px !important;
  }
  .flipClock {
    width: 3 * 60px + 80px;
  }
  .flipUnitContainer {
    width: 50px;
    height: 50px;
  }
  .upperCard,
  .lowerCard {
    span {
      font-size: 27px;
    }
  }
  .flipCard {
    span {
      font-size: 27px;
    }
  }
  .countdown-header {
    font-size: 20px;
  }
  .countdown-timer-units {
    font-size: 14px;
    font-weight: bold;
  }
  .discount-page-video {
    margin-top: 20px !important;
  }
  .login-artwork {
    height: 120vh !important;
  }
  .add-organization-artwork-container {
    height: 120vh !important;
  }
  .register-form-section {
    margin-top: -100px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 991px) {
  .screenshot-laptop-icon {
    max-width: 55% !important;
  }
  .black-friday-image {
    width: 70% !important;
    margin-top: -65px !important;
  }
  .discount-info {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1400px) and (min-width: 1100px) {
  .seven-cols {
    .col-md-1,
    .col-sm-1,
    .col-lg-1 {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }
  }
}

@media screen and (min-width: 1500px) {
  .lg-sub-html {
    padding: 10px 210px !important;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .nav-tabs-navigation.verical-navs {
    padding: 0px 2px;
  }
  .add-or-join-org-button {
    width: 150px !important;
    font-size: 15px !important;
    padding: 8px 3px !important;
  }
  .seven-cols {
    .col-md-1,
    .col-sm-1,
    .col-lg-1 {
      flex: 0 0 25% !important;
      max-width: 25% !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer {
    .footer-nav {
      padding-left: 21px;
    }

    .credits {
      padding-right: 15px;
    }
  }
  .apps-and-url-card {
    min-height: 610px;
  }
}

@media screen and (min-width: 992px) {
  .navbar-collapse {
    background: none !important;
  }

  .navbar .navbar-toggle {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      &.profile-photo {
        padding: 0;
        margin: 7px $padding-base-horizontal;
      }
    }
  }

  .section-nucleo-icons .icons-container {
    margin: 0 0 0 auto;
  }

  .footer {
    .copyright {
      float: right;
      padding-right: 15px;
    }
  }
}

@media screen and (max-height: 768px) {
  .sidebar {
    .sidebar-wrapper {
      li > [data-toggle="collapse"] + div .nav li.active a {
        &:before,
        &:after {
          top: 4px;
        }
      }
      li.active {
        > a:not([data-toggle="collapse"]) {
          // &:before {
          //   border-right: 10px solid $medium-gray;
          //   border-top: 10px solid transparent;
          //   border-bottom: 10px solid transparent;
          //   content: "";
          //   display: inline-block;
          //   position: absolute;
          //   right: -16px;
          //   opacity: 1;
          //   top: 10px;
          //   transition: opacity 150ms ease-in;
          // }

          // &:after {
          //   border-right: 10px solid #f6f6f6;
          //   border-top: 10px solid transparent;
          //   border-bottom: 10px solid transparent;
          //   content: "";
          //   display: inline-block;
          //   position: absolute;
          //   right: -17px;
          //   opacity: 1;
          //   top: 10px;
          //   transition: opacity 150ms ease-in;
          // }
          &:before {
            content: "";
            height: 40px;
            width: 5px;
            position: absolute;
            background: #20bead;
            border-radius: 0px 6px 6px 0px;
            opacity: 1;
            left: 0px;
            top: 3px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .card-stats [class*="col-"] .statistics::after {
    display: none;
  }

  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }

  .landing-page .section-story-overview .image-container:nth-child(2) {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .card {
    .form-horizontal {
      .col-md-3.col-form-label {
        text-align: left;
      }
    }
  }
  .activity-report-card-column {
    display: flex;
    justify-content: center !important;
    width: 100%;
    padding: 0 35px !important;
  }
  .activity-report-card-button {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .project_status_span {
    width: 100%;
    text-align: center;
  }
  .report-date-span {
    width: 100%;
    text-align: center;
  }
  .onboarding-cross-button {
    margin-left: -10px;
  }
  .monthly-timesheet-header {
    font-size: 15px !important;
  }
  .monthly-timesheet-total {
    width: 130px !important;
  }
  .register-form-section {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 650px) {
  .seven-cols {
    .col-md-1,
    .col-sm-1,
    .col-lg-1 {
      flex: 0 0 50% !important;
      max-width: 50% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .biwk-timesheet-date {
    text-align: left !important;
  }
  .biwk-timesheet-hour {
    text-align: center !important;
  }
}
@media screen and (min-width: 767px) {
  .first-name {
    padding-right: 5px;
  }
  .last-name {
    padding-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  .search-bar {
    margin-left: 0 !important;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
  .content-header-wrapper {
    flex-direction: column;
  }

  .custom-header-button {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .manual-time-report-span {
    width: 75% !important;
  }
}

@media screen and (max-width: 768px) {
  .typography-line {
    padding-left: 23% !important;

    span {
      width: 60px !important;
    }
  }

  .login-page,
  .lock-page,
  .register-page {
    .navbar {
      padding: 0.5rem 1rem;
    }
  }

  .footer {
    .footer-nav,
    .credits {
      margin: 0 auto !important;
    }

    .footer-nav {
      margin-bottom: 10px !important;
    }
  }

  .register-page {
    .content {
      padding-top: 5vh;
    }
    .footer {
      position: relative;
    }
    .info-area.info-horizontal {
      margin-top: 0;
    }
  }
  .resend-header {
    margin-top: 25px;
  }
}

@media screen and (max-width: 413px) {
  .fixed-plugin {
    .dropdown.show-dropdown.show {
      .dropdown-menu.show {
        width: 225px !important;

        &[x-placement="top-start"] {
          transform: translate3d(0, 400px, 0) !important;
        }

        &:before,
        &:after {
          bottom: 360px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .seven-cols {
    .col-md-1,
    .col-sm-1,
    .col-lg-1 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .biwk-timesheet-date-time {
    margin-top: 8px !important;
  }
  .biwk-timesheet-date {
    width: 50% !important;
  }
  .biwk-timesheet-hour {
    width: 50% !important;
  }
  .biwk-timesheet-total {
    width: 100% !important;
  }
  .biwk-timesheet-date-range {
    text-align: center;
  }
  .biwk-timesheet-date {
    text-align: left !important;
  }
  .biwk-timesheet-hour {
    text-align: center !important;
  }
}
@media screen and (max-width: 576px) {
  .navbar[class*="navbar-toggleable-"] .container {
    margin-left: 0;
    margin-right: 0;
  }

  .card-contributions .card-stats {
    flex-direction: column;

    .bootstrap-switch {
      margin-bottom: 15px;
    }
  }

  .footer {
    .copyright {
      text-align: center;
    }
  }

  .section-nucleo-icons {
    .icons-container {
      i {
        font-size: 30px;

        &:nth-child(6) {
          font-size: 48px;
        }
      }
    }
  }

  .page-header {
    .container h6.category-absolute {
      width: 90%;
    }
  }

  .card-timeline .timeline {
    .timeline-panel {
      width: 38%;
      padding: 15px;
    }
  }

  .lg-sub-html {
    display: block !important;
    padding: 10px 10px 60px !important;
  }
  .screenshot-desc-col {
    display: flex;
    padding-left: 25px;
  }
  .screenshot-desc-row-mobile {
    margin-left: auto;
  }
  .DateRangePicker {
    width: 100%;
  }
  .DateRangePickerInput {
    width: 100% !important;
  }
  .DateInput {
    width: 100px !important;
  }
}
